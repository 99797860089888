<template>
  <!--  <base-modal width="90%"-->
  <!--              height="auto"-->
  <!--              scrollable-->
  <!--              clickToClose-->
  <!--              @beforeOpen="beforeOpen"-->
  <!--              @beforeClose="beforeClose"-->
  <!--  >-->
  <!--    <template slot="header">{{$t('system.stash')}}</template>-->
  <!--    <template slot="body">-->
  <div class="view">
    <div class="row view-body">
      <div class="col-12 position-static">
        <div class="view pb-2">
          <div class="d-flex justify-content-between">
            <div>
              <el-tag
                  class="mr-1"
                  size="small"
                  type="primary"
              >
                <i class="el-icon-goods" /> {{ countOfSelectedTimeEntries }}
              </el-tag>
              <el-tag
                  size="small"
                  type="success"
              >
                <i class="el-icon-time" /> {{ secondsToHHHmm(timeSumOfSelectedTimeEntries) }}
              </el-tag>
            </div>
            <dropdown-configurator
                :columns="timeEntriesThead"
                :filters="timeEtriesFilters"
                :searchString.sync="searchString"
                :selectedColumns="selectedTimeEntriesThead"
                @clearFilters="clearFilters"
                @setCurrentColumnsConfig="setCurrentColumnsConfig"
                @submitSearch="setFiltersAndFetchData"
                @update:selectedColumns="handleSelectedTimeEntriesThead"
            >
            </dropdown-configurator>
          </div>
          <div class="d-flex justify-content-between mt-2">
            <div>
              <div v-if="showNewSettlementNameInput">
                <el-input
                    :placeholder="$t('system.enter_settlement_name')"
                    @keyup.enter.native="handeleSettlingTimeEntries"
                    autofocus
                    class="mr-1"
                    clearable
                    size="mini"
                    style="width: 300px"
                    v-model="newSettlementName"
                >
                </el-input>
                <el-button-group>
                  <el-button
                      @click.native="handeleSettlingTimeEntries"
                      icon="el-icon-check"
                      size="mini"
                      type="success"
                  >
                  </el-button>
                  <el-button
                      @click.native="toggleNewSettlementForm"
                      icon="el-icon-close"
                      size="mini"
                      type="danger"
                  ></el-button>
                </el-button-group>
              </div>
              <div v-else>
                <el-button
                    :disabled="timeEntries.length === 0 && countOfSelectedTimeEntries === 0"
                    @click.native="toggleNewSettlementForm"
                    size="mini"
                    type="success"
                >
                  {{ countOfSelectedTimeEntries > 0 ? $t('system.settle_selected') : $t('system.settle_all') }}
                </el-button>
                <el-button
                    :disabled="timeEntries.length === 0 && countOfSelectedTimeEntries === 0"
                    @click.native="removeFromStash"
                    size="mini"
                    type="danger"
                >
                  {{ countOfSelectedTimeEntries > 0 ? $t('system.remove_selected') : $t('system.remove_all') }}
                </el-button>
                <!--                <el-dropdown size="small" v-if="docxButton" style="margin-left: 10px" trigger="click" @click.native="fetchSettlements">-->
                <!--                  <el-button size="mini" type="info">-->
                <!--                    {{countOfSelectedTimeEntries > 0 ? $t('system.add_selected_to_settle') : $t('system.add_all_to_settle')}}-->
                <!--                  </el-button>-->
                <!--                  <el-dropdown-menu slot="dropdown">-->
                <!--                    <el-dropdown-item>Rozliczenie 1</el-dropdown-item>-->
                <!--                    <el-dropdown-item>Rozliczenie 2</el-dropdown-item>-->
                <!--                    <el-dropdown-item>Rozliczenie 3</el-dropdown-item>-->
                <!--                  </el-dropdown-menu>-->
                <!--                </el-dropdown>-->
              </div>
            </div>
            <div>
              <!--        <el-button-group>-->
              <el-dropdown
                  size="small"
                  v-if="docxButton"
              >
                <el-button
                    :disabled="timeEntries.length === 0 && countOfSelectedTimeEntries === 0"
                    @click.native="handleGenerateReport('pdf')"
                    size="mini"
                    type="primary"
                >PDF
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="handleGenerateReport('pdf', true)">{{ $t('system.landscape_mode') }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown
                  size="small"
                  v-if="pdfButton"
              >
                <el-button
                    :disabled="timeEntries.length === 0 && countOfSelectedTimeEntries === 0"
                    @click.native="handleGenerateReport('docx')"
                    class="ml-1"
                    size="mini"
                    type="primary"
                >DOC
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="handleGenerateReport('docx', true)">{{ $t('system.landscape_mode') }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <!--        </el-button-group>-->
            </div>
          </div>
        </div>
        <checkable-table
            :align-left="['time_entries.comments']"
            :data="timeEntries"
            :isCheckedAll.sync="isCheckedAll"
            :pageSizes="[10, 30, 50, 100]"
            :params.sync="params"
            :selectedIds="selectedTimeEntriesIds"
            :thead="selectedTimeEntriesThead"
            :total="totalTimeEntriesCount"
            @clearAllSelections="clearAllTimeEntriesSelections"
            @fetchData="fetchData"
            @selectionChange="setTimeEntrySelectionChange"
            idColumn="time_entries.id"
            pagination
        >
        </checkable-table>
      </div>
    </div>
  </div>
  <!--    </template>-->
  <!--    <template slot="footer"></template>-->
  <!--  </base-modal>-->
</template>

<script>
import {cloneDeep} from 'lodash';
import {mapActions, mapGetters} from 'vuex';
import swal from 'sweetalert2';
import CheckableTable from './checkableTable.vue';
import DropdownConfigurator from '../../components/DropdownConfigurator/crmDropdownConfigurator.vue';
import notification from '../../notification/notify';
import fileGenerator from './fileGenerator';
import time from '../../mixin/time';
import EventBus from '../../eventBus';

export default {
  name: 'Stash',
  mixins: [fileGenerator, time],
  components: {
    // BaseModal,
    CheckableTable,
    DropdownConfigurator,
  },
  props: {
    docxButton: {
      type: Boolean,
      default: false,
    },
    pdfButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showNewSettlementNameInput: false,
      newSettlementName: '',
      oldCheckedRows: [],
      settlements: [],
    };
  },
  beforeMount() {
    this.fetchData();
    this.fetchIssueStatuses();
  },
  created() {
    EventBus.$on('CLOSE_ISSUE_MODAL', async () => {
      this.setTimeSumOfSelectedTimeEntries(await this.getTimeEntriesSum(this.selectedTimeEntriesIds).then((response) => {
        return response.data.timesSum;
      }));
      this.fetchData();
    });
  },
  beforeDestroy() {
    EventBus.$off('CLOSE_ISSUE_MODAL');
  },
  computed: {
    ...mapGetters('unsettled_reports_stash', [
      'timeEntries',
      'selectedTimeEntriesThead',
      'totalTimeEntriesCount',
      'selectedTimeEntriesIds',
      'timeEntriesThead',
      'timeEtriesFilters',
      'countOfSelectedTimeEntries',
      'timeSumOfSelectedTimeEntries',
    ]),
    ...mapGetters('unsettled_reports', {
      timeEntriesIds: 'selectedTimeEntriesIds',
    }),
    ...mapGetters('unsettled_reports', [
      'allTimeEntriesIds',
    ]),
    params: {
      get() {
        return this.$store.getters['unsettled_reports_stash/timeEntriesListParams'];
      },
      async set(value) {
        await this.$store.dispatch('unsettled_reports_stash/setTimeEntriesListParams', value);
        this.fetchData();
      },
    },
    searchString: {
      get() {
        return this.$store.getters['unsettled_reports_stash/timeEntriesListSearchString'];
      },
      async set(value) {
        await this.$store.dispatch('unsettled_reports_stash/setTimeEntriesListSearchString', value);
      },
    },
    isCheckedAll: {
      get() {
        return this.$store.getters['unsettled_reports_stash/isCheckedAllTimeEntries'];
      },
      async set(value) {
        await this.selectAllTimeEntries(value);
      },
    },
  },
  methods: {
    ...mapActions('unsettled_reports_stash', [
      'setTimeEntrySelectionChange',
      'clearAllTimeEntriesSelections',
      'fetchTimeEntries',
      'setSelectedTimeEntriesThead',
      'setCurrentColumnsConfig',
      'fetchIssueStatuses',
      'settleTimeEntries',
      'selectAllTimeEntries',
      'setTimeSumOfSelectedTimeEntries',
      'clearTimeEntriesFilters',
    ]),
    ...mapActions('unsettled_reports', [
      'removeSelectedTimeEntriesIds',
    ]),
    handleSelectedTimeEntriesThead(columns) {
      this.setSelectedTimeEntriesThead(columns);
      this.fetchData();
    },
    setFiltersAndFetchData(filters) {
      this.setFilters(filters);
      this.fetchData();
    },
    // fetchSettlements() {
    //   axios.post('settlements', {
    //     project_id: this.$route.params.projectId,
    //     columns: ['settlements.id', 'settlements.name'],
    //     search: '',
    //     filters: {},
    //     settled: false
    //   }).then((response) => {
    //     this.settlements = response.data
    //   })
    // },
    clearFilters() {
      this.clearTimeEntriesFilters();
      this.fetchData();
    },
    async handleGenerateReport(type, landscape = false) {
      let ids = [];
      if (this.countOfSelectedTimeEntries === 0) {
        ids = this.timeEntriesIds;
      } else {
        ids = this.selectedTimeEntriesIds;
      }

      const requestFilters = {};
      this.timeEtriesFilters.forEach(item => {
        if (item.type === 'checkbox' || item.type === 'switch') {
          requestFilters[item.name] = !!item.value;
        } else {
          requestFilters[item.name] = item.value;
        }
      });

      if (ids.length > 200) {
        swal.fire({
          title: this.$t('system.if_you_sure'),
          text: '200 ' + this.$t('system.max_rows'),
          type: 'info',
          showCancelButton: true,
          focusCancel: true,
          confirmButtonText: this.$t('system.yes'),
          cancelButtonText: this.$t('system.no'),
        }).then((result) => {
          if (result.value) {
            this.generateReport(ids, this.selectedTimeEntriesThead, type, landscape, this.params.column, this.params.direction, requestFilters).then((response) => {
              const header = response.headers['content-disposition'];
              let name = header.slice(header.indexOf('=') + 1, header.length).toString();
              if (name.startsWith('"')) {
                name = name.replace('"', '');
              }
              this.downloadFile(response.data, type, name);
            });
          }
        });
      } else {
        this.generateReport(ids, this.selectedTimeEntriesThead, type, landscape, this.params.column, this.params.direction, requestFilters).then((response) => {
          const header = response.headers['content-disposition'];
          let name = header.slice(header.indexOf('=') + 1, header.length).toString();
          if (name.startsWith('"')) {
            name = name.replace('"', '');
          }
          this.downloadFile(response.data, type, name);
        });
      }
    },
    setFilters(filters) {
      this.$store.dispatch('unsettled_reports_stash/setFilters', {filters});
    },
    async removeFromStash() {
      if (this.countOfSelectedTimeEntries > 0) {
        //remove selected
        await this.removeSelectedTimeEntriesIds(this.selectedTimeEntriesIds);
        this.clearAllTimeEntriesSelections();
      } else {
        //remove all
        await this.removeSelectedTimeEntriesIds(this.allTimeEntriesIds);
      }
      this.setTimeSumOfSelectedTimeEntries(0);
      this.fetchData();
    },
    async handeleSettlingTimeEntries() {
      const {data} = await this.settleTimeEntries({
        name: this.newSettlementName,
        ids: this.countOfSelectedTimeEntries > 0 ? this.selectedTimeEntriesIds : this.allTimeEntriesIds,
      });
      notification.notify(
          data.saved ? this.$t('notify.success') : this.$t('notify.error'),
          this.$t(data.message),
          data.type);
      if (data.saved) {
        this.toggleNewSettlementForm();
        await this.removeSelectedTimeEntriesIds(this.countOfSelectedTimeEntries > 0 ? this.selectedTimeEntriesIds : this.allTimeEntriesIds);
        this.clearAllTimeEntriesSelections();
        this.setTimeSumOfSelectedTimeEntries(0);
        this.fetchData();
      }
    },
    toggleNewSettlementForm() {
      this.newSettlementName = '';
      this.showNewSettlementNameInput = !this.showNewSettlementNameInput;
      if (this.showNewSettlementNameInput) {
        this.oldCheckedRows = cloneDeep(this.selectedTimeEntriesIds);
      }
      if (this.countOfSelectedTimeEntries === 0) {
        this.isCheckedAll = !this.isCheckedAll;
      } else if (!this.showNewSettlementNameInput && this.oldCheckedRows.length === 0) {
        this.isCheckedAll = !this.isCheckedAll;
        this.oldCheckedRows = [];
      }
      // if (this.countOfSelectedTimeEntries === 0 && this.showNewSettlementNameInput) {
      //   this.selectAllTimeEntries(true)
      // } else if (!this.showNewSettlementNameInput) {
      //   this.selectAllTimeEntries(false)
      // }
    },
    // async settleTimeEntries(name) {
    //   return this.$store.dispatch( + '/settleTimeEntries', name)
    // },
    async fetchData() {
      await this.fetchTimeEntries({timeEntriesIds: this.timeEntriesIds});
    },
    beforeOpen() {
      this.fetchData();
    },
    beforeClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
