<template>
  <div class="view">
    <div class="row view-body">
      <div class="col-4 position-static right-border">
        <projects-card />
      </div>
      <div class="col-8 position-static">
        <time-entries-card :namespace="'unsettled_reports'" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import ProjectsCard from './projectsCard.vue';
import TimeEntriesCard from './timeEntriesCard.vue';

export default {
  name: 'Unsettled',
  components: {
    ProjectsCard,
    TimeEntriesCard,
  },
  beforeMount() {
    this.getStashedTimeEntries();
  },
  methods: {
    ...mapActions('unsettled_reports', [
      'getStashedTimeEntries',
    ]),
  },
};
</script>

<style scoped>

</style>
