<template>
  <div class="view-container container-default ml_1">
    <el-tabs
        class="settlements-tabs custom_tabs"
        type="card"
        v-model="activeTabPane"
    >
      <el-tab-pane
          :label="$t('report.unsettled')"
          name="unsettled"
      >
        <unsettled v-if="activeTabPane === 'unsettled'" />
      </el-tab-pane>
      <el-tab-pane name="stash">
        <span slot="label">{{ $t('report.stash') }}<el-badge
            :value="countOfSelectedTimeEntries"
            class="font-weight-100"
            type="success"
            v-if="countOfSelectedTimeEntries > 0"
        /></span>
        <stash
            docxButton
            pdfButton
            v-if="activeTabPane === 'stash'"
        >
        </stash>
      </el-tab-pane>
      <el-tab-pane
          :label="$t('report.settled')"
          name="settled"
      >
        <settled v-if="activeTabPane === 'settled'" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Settled from './Settled.vue';
import Unsettled from './Unsettled.vue';
import Stash from './stash.vue';
import {getLocalStorage, setLocalStorage} from '../../helpers/localStorage';

export default {
  name: 'ReportIndex',
  components: {
    Settled,
    Unsettled,
    Stash,
  },
  data() {
    return {
      activeTabPane: 'unsettled',
    };
  },
  beforeMount() {
    // console.log('this.currentProjectIdInSettledReports', this.currentProjectIdInSettledReports)
    // console.log('this.currentProjectId', this.currentProjectId)
    // console.log('this.currentProjectIdInSettledReports !== this.currentProjectId', this.currentProjectIdInSettledReports !== this.currentProjectId)
    const reportColumns = getLocalStorage('reportColumns');
    if (!reportColumns) {
      setLocalStorage('reportColumns', {
        unsettled: {
          projects: this.unsettledProjectsColumns,
          timeEntries: this.unsettledTimeEntriesColumns,
        },
        stash: this.stashColumns,
        settled: {
          settlements: this.settledSettlementsColumns,
          timeEntries: this.settledTimeEntriesColumns,
        },
      });
    } else {
      // set columns from LS
      // unsettled
      this.setUnsettledSelectedProjectsThead(reportColumns.unsettled.projects);
      this.setUnsettledSelectedTimeEntriesThead(reportColumns.unsettled.timeEntries);
      // stash
      this.setStashSelectedTimeEntriesThead(reportColumns.stash);
      // settled
      this.setSettledSelectedSettlementsThead(reportColumns.settled.settlements);
      this.setSettledSelectedTimeEntriesThead(reportColumns.settled.timeEntries);
    }
    if (this.currentProjectIdInSettledReports !== this.currentProjectId) {
      //Unsettled
      this.setProjectsListSearchString('');
      this.clearAllProjectsSelections();
      this.clearAllProjects();
      this.$store.dispatch('unsettled_reports/setTimeEntriesListSearchString', '');
      this.$store.dispatch('unsettled_reports/clearAllTimeEntriesSelections');
      this.$store.dispatch('unsettled_reports/clearAllTimeEntries');
      //Settled
      this.setSettlementsListSearchString('');
      this.clearAllSettlementsSelections();
      this.clearAllSettlements();
      this.$store.dispatch('settled_reports/setTimeEntriesListSearchString', '');
      this.$store.dispatch('settled_reports/clearAllTimeEntriesSelections');
      this.$store.dispatch('settled_reports/clearAllTimeEntries');

      this.setCurrentProjectId(this.currentProjectId);
    }
    // this.$store.dispatch('unsettled_reports/setTimeEntriesThead');
    // this.$store.dispatch('settled_reports/setTimeEntriesThead');
    // this.$store.dispatch('stash/setTimeEntriesThead');
  },
  computed: {
    ...mapGetters({
      currentProjectIdInSettledReports: 'unsettled_reports/currentProjectId',
      unsettledProjectsColumns: 'unsettled_reports/selectedProjectsThead',
      unsettledTimeEntriesColumns: 'unsettled_reports/selectedTimeEntriesThead',
      stashColumns: 'unsettled_reports_stash/selectedTimeEntriesThead',
      settledSettlementsColumns: 'settled_reports/selectedSettlementsThead',
      settledTimeEntriesColumns: 'settled_reports/selectedTimeEntriesThead',
    }),
    ...mapGetters('unsettled_reports', ['countOfSelectedTimeEntries']),
    ...mapGetters(['currentProjectId']),
  },
  methods: {
    ...mapActions('unsettled_reports', [
      'setProjectsListSearchString',
      'clearAllProjectsSelections',
      'clearAllProjects',
      'setCurrentProjectId',
    ]),
    ...mapActions({
      setUnsettledSelectedProjectsThead: 'unsettled_reports/setSelectedProjectsThead',
      setUnsettledSelectedTimeEntriesThead: 'unsettled_reports/setSelectedTimeEntriesThead',
      setStashSelectedTimeEntriesThead: 'unsettled_reports_stash/setSelectedTimeEntriesThead',
      setSettledSelectedSettlementsThead: 'settled_reports/setSelectedSettlementsThead',
      setSettledSelectedTimeEntriesThead: 'settled_reports/setSelectedTimeEntriesThead',
    }),
    ...mapActions('settled_reports', [
      'setSettlementsListSearchString',
      'clearAllSettlementsSelections',
      'clearAllSettlements',
    ]),
  },
};
</script>

<style
    lang="scss"
    rel="stylesheet/scss"
>
.settlements-tabs {
  .el-tabs__content {
    position: static;
  }
}
</style>
