<template>
  <div class="report--cart">
    <div class="view pb-2">
      <div class="w-100">
        <h5 class="d-inline-block"><b>{{ $t('system.projects') }}</b></h5>
        <dropdown-configurator
            :columns="projectsThead"
            :searchString.sync="searchString"
            :selectedColumns="selectedProjectsThead"
            @submitSearch="fetchData"
            @update:selectedColumns="selectedColumns"
            class="d-inline-block float-right"
            disableFilters
            position="left"
        >
        </dropdown-configurator>
      </div>
      <el-tag
          class="mt-1"
          size="small"
          type="primary"
      >
        <i class="el-icon-goods"></i> {{ countOfSelectedProjects }}
      </el-tag>
    </div>
    <checkable-table
        :align-left="['projects.name']"
        :data="projects"
        :isCheckedAll.sync="isCheckedAll"
        :pageSizes="[10, 30, 50, 100]"
        :params.sync="params"
        :selectedIds="selectedProjectsIds"
        :thead="selectedProjectsThead"
        :total="totalProjectsCount"
        @selectionChange="handleProjectSelectionChange"
        id-column="projects.id"
        pagination
    >
    </checkable-table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import CheckableTable from './checkableTable.vue';
import DropdownConfigurator from '../../components/DropdownConfigurator/crmDropdownConfigurator.vue';

export default {
  name: 'projectsCard',
  components: {
    CheckableTable,
    DropdownConfigurator,
  },
  beforeMount() {
    this.fetchData();
  },
  computed: {
    ...mapGetters('unsettled_reports', [
      'selectedProjectsIds',
      'projectsThead',
      'selectedProjectsThead',
      'projects',
      'isCheckedAllProjects',
      'totalProjectsCount',
      'projectsListParams',
      'projectsListSearchString',
      'countOfSelectedProjects',
    ]),
    isCheckedAll: {
      get() {
        return this.isCheckedAllProjects;
      },
      async set(value) {
        await this.selectAllProjects(value);
        this.fetchTimeEntries();
      },
    },
    params: {
      get() {
        return this.projectsListParams;
      },
      async set(value) {
        await this.setProjectsListParams(value);
        this.fetchData();
      },
    },
    searchString: {
      get() {
        return this.projectsListSearchString;
      },
      set(value) {
        this.setProjectsListSearchString(value);
      },
    },
  },
  methods: {
    ...mapActions('unsettled_reports', [
      'setProjectSelectionChange',
      'selectAllProjects',
      'fetchProjects',
      'setSelectedProjectsThead',
      'setProjectsListParams',
      'setProjectsListSearchString',
      'fetchTimeEntries',
    ]),
    async selectedColumns(columns) {
      await this.setSelectedProjectsThead(columns);
      await this.fetchData();
    },
    async handleProjectSelectionChange(id) {
      await this.setProjectSelectionChange(id);
      await this.fetchTimeEntries();
    },
    async fetchData() {
      await this.fetchProjects();
    },
  },
};
</script>
