<template>
  <div class="view">
    <div class="row view-body">
      <div class="col-4 position-static right-border">
        <settlements-card />
      </div>
      <div class="col-8 position-static">
        <time-entries-card
            :namespace="'settled_reports'"
            docxButton
            pdfButton
            unsettleButton
        />
      </div>
    </div>
  </div>
</template>

<script>
import SettlementsCard from './settlementsCard.vue';
import TimeEntriesCard from './timeEntriesCard.vue';

export default {
  name: 'Settled',
  components: {
    SettlementsCard,
    TimeEntriesCard,
  },
};
</script>

<style scoped>

</style>
